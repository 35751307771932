const readCookie = name => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return "";
};

const getUtmCookie = () => {
  const utm_campaign = readCookie("utm_campaign");
  const utm_medium = readCookie("utm_medium");
  const utm_source = readCookie("utm_source");
  const utm_content = readCookie("utm_content");
    return {
      utm_campaign,
      utm_medium,
      utm_source,
      utm_content,
    };
};

const getUtmParams = () => {
  const location_str = window.location.toString();
  const querystring = location_str.split("?");
  const params = {};
  if (querystring.length > 1) {
    const pairs = querystring[1].split("&");
    for (let i in pairs) {
      const keyval = pairs[i].split("=");
      params[keyval[0]] = keyval[1];
    }
  }
  if (Object.keys(params).length === 0 && params.constructor === Object) {
    return null;
  } else {
    return {
      utm_campaign: params.utm_campaign,
      utm_medium: params.utm_medium,
      utm_source: params.utm_source,
      utm_term: params.utm_term,
    };
  }
};

export { readCookie, getUtmCookie, getUtmParams };
