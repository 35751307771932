import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import Seo from "../../seo";
import Layout from "../layout";
import IndivRecord from "./indivrecord";
import CheckoutVideo from "./checkoutvideo";
// import { webinarVideosDetails } from "./AllState.js";

import { talksDetailsState } from "./talkstate.js";

import {
  StyledPatternPos,
  StyledContainerWrapper,
  StyledConfDesc,
  StyledConfTitle,
  StyledConfDescSmall,
  StyledRecordingTwoColumns
} from "../styles/StyledEGConf";

import leftArrowWhite from "../images/left-arrow-white.svg";
import leftArrowBlue from "../images/left-arrow-blue.svg";
import videoImg from "../images/video.svg";
import Paperform from "../../contactus/Paperform";

const StyledPostConfTopBanner = styled.div`
  padding: 90px 0;
  .back {
    display: flex;
    align-items: center;
    img {
      margin-right: 12px;
    }
  }
  .videoWrapper {
    margin-top: 32px;
    margin-bottom: -180px;
    box-shadow: 0px 14px 64px rgba(0, 0, 0, 0.34);
    border-radius: 8px;
    background-color: #00315F;
    min-height: 400px;
    .videoAspectRatio {
      position: relative;
      padding-bottom: 56.2%;
    }
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
  .posCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 767px) {
    padding: 50px 0;
    .videoWrapper {
      margin-bottom: -100px;
      min-height: auto;
    }
    .posCenter {
      padding: 20px;
    }
  }
`;

const StyledDescWrapper = styled.div`
  padding-top: 180px;
  display: flex;
  align-items: flex-start;
  .flexOne {
    flex: 1;
    padding-right: 200px;
  }
  ul {
    padding: 32px 0;
    padding-bottom: 8px;
    li {
      svg {
        min-width: 24px;
      }
    }
  }
  .speakersthumbWrapper {
    display: flex;
    padding-bottom: 24px;
    .speakerImg {
      padding-right: 16px;
      img {
        width: 48px;
      }
    }
    .flexOne {
      flex: 1;
      padding-right: 0px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .flexOne {
      padding-right: 60px;
    }
  }
  @media (max-width: 767px) {
    display: block;
    padding-top: 100px;
    .flexOne {
      padding-right: 0px;
      padding-bottom: 32px;
    }
  }
`;

const StyledOtherRecording = styled.div`
  padding: 60px 0;
  .otherRecording {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      margin-right: 12px;
    }
  }
  .back {
    display: flex;
    align-items: center;
    padding-top: 60px;
    img {
      margin-right: 12px;
    }
  }
  @media (max-width: 767px) {
    padding-top: 0;
  }
`;

const IndivRecordingPage = props => {
  const talksDetailsUrl = props.pageContext.slug;
  const currenttalksDetails = talksDetailsState[talksDetailsUrl];
  if (!currenttalksDetails) {
    if (typeof window !== undefined) {
      window.location.href = "/404";
    }
  }
  const ogImage = { ogImage: currenttalksDetails.metaTags.metaImg };

  const search = props.location.search;
  const [isLocalConsent, setIsLocalConsent] = useState(false);

  const onSubmitCB = () => {
    if (typeof window !== undefined) {
      window.localStorage.setItem("egcConsent", "true");
    }
  };
  useEffect(() => {
    if (typeof window !== undefined) {
      if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
        const egcConsent = window.localStorage.getItem("egcConsent");
        if (egcConsent) {
          setIsLocalConsent(true);
        }
      }
    }
  }, [search]);

  return (
    <Layout location={props.location}>
      <Seo
        title={currenttalksDetails.metaTags.title}
        description={currenttalksDetails.metaTags.description}
        meta={ogImage}
        canonicalLink={currenttalksDetails.metaTags.canonicalUrl}
      />
      <StyledPatternPos>
        <StyledContainerWrapper>
          <StyledPostConfTopBanner>
            <Link to="/enterprisegraphql/2021/">
              <StyledConfDesc className="back" blueColor="#fff" fontBold="bold">
                <img src={leftArrowWhite} alt="Arrow" />
                Conference homepage
              </StyledConfDesc>
            </Link>
            {
              currenttalksDetails.isGated ? (
                <>
                  {
                    isLocalConsent ? (
                      <div className="videoWrapper">
                        <div className="videoAspectRatio">
                          <iframe
                            loading="lazy"
                            title={currenttalksDetails.title}
                            src={currenttalksDetails.videoLink}
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    ) : (
                      <div className="videoWrapper posCenter">
                        <Paperform
                          onSubmitCB={onSubmitCB}
                          formId="hf-1211"
                          styleClass="marketoFormWrapper egcFormWrapper"
                        />
                      </div>
                    )
                  }
                </>
              ) : (
                <div className="videoWrapper">
                  <div className="videoAspectRatio">
                    <iframe
                      loading="lazy"
                      title={currenttalksDetails.title}
                      src={currenttalksDetails.videoLink}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              )
            }
          </StyledPostConfTopBanner>
        </StyledContainerWrapper>
      </StyledPatternPos>
      <StyledContainerWrapper>
        <StyledDescWrapper>
          <div className="flexOne">
            <StyledConfTitle pb="32px" blueColor="#1B2738">{currenttalksDetails.title}</StyledConfTitle>
            {currenttalksDetails.talkDesc}
          </div>
          <div>
            <StyledConfDescSmall blueColor="#139F94" pb="24px"  fontBold="bold">SPEAKERS</StyledConfDescSmall>
            {
              currenttalksDetails.authorDetails.map((speaker, index) => (
                <div key={index} className="speakersthumbWrapper">
                  <div className="speakerImg">
                    <img src={speaker.authorImg} alt={speaker.name} />
                  </div>
                  <div className="flexOne">
                    <StyledConfDescSmall pb="0px" fontBold="bold">{speaker.name}</StyledConfDescSmall>
                    <StyledConfDescSmall>{speaker.org}</StyledConfDescSmall>
                  </div>
                </div>
              ))
            }
          </div>
        </StyledDescWrapper>
        <StyledOtherRecording>
          <StyledConfDesc className="otherRecording" fontBold="bold"><img src={videoImg} alt="Video" />Other Recordings</StyledConfDesc>
          <StyledRecordingTwoColumns>
            {
              currenttalksDetails.otherRegardings.map((recordItem, index)=> (
                <IndivRecord key={index} recordings={recordItem} />
              ))
            }
          </StyledRecordingTwoColumns>
          <Link to="/enterprisegraphql/2021/">
            <StyledConfDesc className="back" blueColor="#1B2738" fontBold="bold">
              <img src={leftArrowBlue} alt="Arrow" />
              Conference homepage
            </StyledConfDesc>
          </Link>
        </StyledOtherRecording>
      </StyledContainerWrapper>
      <CheckoutVideo />
    </Layout>
  );
};

export default IndivRecordingPage;
