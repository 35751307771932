import React from "react";
import { StyledConfDesc } from "../styles/StyledEGConf";

import tanmaiTalk from "../images/tanmai-talk.png";
import ratchelTalk from "../images/ratchel-talk.png";
import shyamalJagannathMyeeTalk from "../images/shyamal-jagannath-myee-talk.png";
import arunTalk from "../images/arun-talk.png";
import zhamakTanmaiTalk from "../images/zhamak-tanmai-talk.png";

import tanmai from "../images/tanmai.png";
import rachelStephens from "../images/rachel-stephens.png";
import shyamalArora from "../images/shyamal-arora.png";
import arunVijayvergiya from "../images/arun-vijayvergiya.png";
import jagannathVaikuntham from "../images/jagannath-vaikuntham.png";
import myeeRiri from "../images/myee-riri.png";
import proZhamakDehghani from "../images/pro-zhamak-dehghani.jpg";

const ChevronRight = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.28859 6.70978C8.89859 7.09978 8.89859 7.72978 9.28859 8.11978L13.1686 11.9998L9.28859 15.8798C8.89859 16.2698 8.89859 16.8998 9.28859 17.2898C9.67859 17.6798 10.3086 17.6798 10.6986 17.2898L15.2886 12.6998C15.6786 12.3098 15.6786 11.6798 15.2886 11.2898L10.6986 6.69978C10.3186 6.31978 9.67859 6.31978 9.28859 6.70978Z" fill="black"/>
    </svg>
  )
}

const talksDetailsState = {
  "/enterprisegraphql/2021/build-a-graphql-powered-data-mesh-for-your-polyglot-data/": {
    metaTags: {
      title: "Build a GraphQL powered data mesh for your polyglot data | Enterprise GraphQL Conf",
      canonicalUrl: "https://hasura.io/enterprisegraphql/2021/build-a-graphql-powered-data-mesh-for-your-polyglot-data/",
      description:
        "Tanmai Gopal, Hasura’s CEO, will introduce the concept of a data mesh and the benefits of using it as a solution to data (both online and analytical) and application modernization problems.",
      metaImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/enterprisegraphql/build-a-graphql-powered-data-mesh-for-your-polyglot-data.png",
    },
    talkType: "KEYNOTE",
    title: "Build a GraphQL powered data mesh for your polyglot data",
    // isGated: true,
    isGated: false,
    talkDesc: <StyledConfDesc>Tanmai Gopal, Hasura’s CEO, will introduce the concept of a data mesh and the benefits of using it as a solution to data (both online and analytical) and application modernization problems. He’ll talk about the key requirements around ...<br/>
      <ul>
        <li>
          <ChevronRight />
          domain-oriented decentralized data ownership and architecture
        </li>
        <li>
          <ChevronRight />
          data as a product
        </li>
        <li>
          <ChevronRight />
          self-serve data infrastructure as a platform
        </li>
        <li>
          <ChevronRight />
          federated computational governance <a href="https://martinfowler.com/articles/data-mesh-principles.html#CorePrinciplesAndLogicalArchitectureOfDataMesh" target="_blank" rel="noopener noreferrer">(ref)</a>.
        </li>
      </ul><br/>
      Finally, he will talk about how GraphQL opens up an opportunity for laying the foundation for a data mesh and best-practices in building, operating and maintaining an enterprise grade data mesh.
    </StyledConfDesc>,
    thumbImgSrc: tanmaiTalk,
    videoLink: "https://player.vimeo.com/video/645910322",
    homepageConf: true,
    authorDetails: [
      {
        authorImg: tanmai,
        name: 'Tanmai Gopal',
        designation: 'CEO,',
        org: "Hasura",
      },
    ],
    otherRegardings: [
      {
        talkType: "FIRESIDE CHAT",
        title: "Fireside Chat: GraphQL & the Data Mesh",
        linkUrl: "/enterprisegraphql/2021/graphql-the-data-mesh/",
        recordThumb: zhamakTanmaiTalk,
      },
      {
        talkType: "TALK",
        title: "The Ghosts of Databases Past, Present and Future",
        linkUrl: "/enterprisegraphql/2021/the-ghosts-of-databases-past-present-and-future",
        recordThumb: ratchelTalk,
      },
      {
        talkType: "TALK",
        title: "Improving quality of life for Aussie farmers",
        linkUrl: "/enterprisegraphql/2021/improving-quality-of-life-for-aussie-farmers/",
        recordThumb: shyamalJagannathMyeeTalk,
      },
      {
        talkType: "TALK",
        title: "GraphQL, the gateway to a New Service Paradigm",
        linkUrl: "/enterprisegraphql/2021/graphql-the-gateway-to-a-new-service-paradigm/",
        recordThumb: arunTalk,
      },
    ],
  },
  "/enterprisegraphql/2021/graphql-the-data-mesh/": {
    metaTags: {
      title: "Fireside Chat: GraphQL & the Data Mesh | Enterprise GraphQL Conf",
      canonicalUrl: "https://hasura.io/enterprisegraphql/2021/graphql-the-data-mesh/",
      description:
        "In this finale of the conference, Zhamak Dehghani, the creator of the Data Mesh concept, and Tanmai Gopal, the co-founder and CEO of Hasura will chat about how operational systems technologies like GraphQL can be brought closer to analytical systems, some of the common patterns while dealing with data security and performance and the overall movement towards productivity in the enterprise.",
      metaImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/enterprisegraphql/graphql-the-data-mesh.png",
    },
    talkType: "FIRESIDE CHAT",
    title: "Fireside Chat: GraphQL & the Data Mesh",
    isGated: false,
    talkDesc: <StyledConfDesc>In this finale of the conference, Zhamak Dehghani, the creator of the Data Mesh concept, and Tanmai Gopal, the co-founder and CEO of Hasura will chat about how operational systems technologies like GraphQL can be brought closer to analytical systems, some of the common patterns while dealing with data security and performance and the overall movement towards productivity in the enterprise.</StyledConfDesc>,
    thumbImgSrc: zhamakTanmaiTalk,
    videoLink: "https://player.vimeo.com/video/645921404",
    authorDetails: [
      {
        authorImg: proZhamakDehghani,
        name: 'Zhamak Dehghani',
        designation: 'Principal Consultant,',
        org: "Thoughtworks",
      },
      {
        authorImg: tanmai,
        name: 'Tanmai Gopal',
        designation: 'CEO,',
        org: "Hasura",
      },
    ],
    otherRegardings: [
      {
        talkType: "KEYNOTE",
        title: "Build a GraphQL powered data mesh for your polyglot data",
        linkUrl: "/enterprisegraphql/2021/build-a-graphql-powered-data-mesh-for-your-polyglot-data/",
        recordThumb: tanmaiTalk,
      },
      {
        talkType: "TALK",
        title: "The Ghosts of Databases Past, Present and Future",
        linkUrl: "/enterprisegraphql/2021/the-ghosts-of-databases-past-present-and-future",
        recordThumb: ratchelTalk,
      },
      {
        talkType: "TALK",
        title: "Improving quality of life for Aussie farmers",
        linkUrl: "/enterprisegraphql/2021/improving-quality-of-life-for-aussie-farmers/",
        recordThumb: shyamalJagannathMyeeTalk,
      },
      {
        talkType: "TALK",
        title: "GraphQL, the gateway to a New Service Paradigm",
        linkUrl: "/enterprisegraphql/2021/graphql-the-gateway-to-a-new-service-paradigm/",
        recordThumb: arunTalk,
      },
    ],
  },
  "/enterprisegraphql/2021/the-ghosts-of-databases-past-present-and-future/": {
    metaTags: {
      title: "The Ghosts of Databases Past, Present and Future | Enterprise GraphQL Conf",
      canonicalUrl: "https://hasura.io/enterprisegraphql/2021/the-ghosts-of-databases-past-present-and-future/",
      description:
        "The industry's approach to developing software has evolved materially over the years, but that rate of change has not always been reflected in the database world.",
      metaImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/enterprisegraphql/the-ghosts-of-databases-past-present-and-future.png",
    },
    talkType: "TALK",
    title: "The Ghosts of Databases Past, Present and Future",
    // isGated: true,
    isGated: false,
    talkDesc: <StyledConfDesc>The industry's approach to developing software has evolved materially over the years, but that rate of change has not always been reflected in the database world. However, there is evidence this resistance is decreasing. As application architectures become more complex and decentralized, companies need to strategically consider the people, processes, and tools involved in their data layers. This talk will explore some of the historic factors that have shaped the database world, the forces that brought us to today, and where we go from here.</StyledConfDesc>,
    thumbImgSrc: ratchelTalk,
    videoLink: "https://player.vimeo.com/video/645913749",
    authorDetails: [
      {
        authorImg: rachelStephens,
        name: 'Rachel Stephens',
        designation: 'Senior Analyst,',
        org: "Redmonk",
      },
    ],
    otherRegardings: [
      {
        talkType: "KEYNOTE",
        title: "Build a GraphQL powered data mesh for your polyglot data",
        linkUrl: "/enterprisegraphql/2021/build-a-graphql-powered-data-mesh-for-your-polyglot-data/",
        recordThumb: tanmaiTalk,
      },
      {
        talkType: "FIRESIDE CHAT",
        title: "Fireside Chat: GraphQL & the Data Mesh",
        linkUrl: "/enterprisegraphql/2021/graphql-the-data-mesh/",
        recordThumb: zhamakTanmaiTalk,
      },
      {
        talkType: "TALK",
        title: "Improving quality of life for Aussie farmers",
        linkUrl: "/enterprisegraphql/2021/improving-quality-of-life-for-aussie-farmers/",
        recordThumb: shyamalJagannathMyeeTalk,
      },
      {
        talkType: "TALK",
        title: "GraphQL, the gateway to a New Service Paradigm",
        linkUrl: "/enterprisegraphql/2021/graphql-the-gateway-to-a-new-service-paradigm/",
        recordThumb: arunTalk,
      },
    ],
  },
  "/enterprisegraphql/2021/improving-quality-of-life-for-aussie-farmers/": {
    metaTags: {
      title: "Improving quality of life for Aussie farmers | Enterprise GraphQL Conf",
      canonicalUrl: "https://hasura.io/enterprisegraphql/2021/improving-quality-of-life-for-aussie-farmers/",
      description:
        "As the world's largest provider of crop inputs and services, Nutrien plays a critical role in helping farmers grow food in a sustainable manner. Nutrien has been operating in Australia for more than 150 years providing expertise in a range of different areas, including precision farming services, marketing livestock and wool, agricultural services, finance, insurance, merchandise, and real estate.",
      metaImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/enterprisegraphql/improving-quality-of-life-for-aussie-farmers.png",
    },
    talkType: "TALK",
    title: "Improving quality of life for Aussie farmers",
    // isGated: true,
    isGated: false,
    talkDesc: <StyledConfDesc>As the world's largest provider of crop inputs and services, Nutrien plays a critical role in helping farmers grow food in a sustainable manner. Nutrien has been operating in Australia for more than 150 years providing expertise in a range of different areas, including precision farming services, marketing livestock and wool, agricultural services, finance, insurance, merchandise, and real estate. For a grower, running a profitable agricultural business requires successful farm and financial planning. As part of the Digital team, we are on a mission to become famous for outstanding, state-of-the-art service and ultimately make our growers' lives simpler and easier. We do this by providing a range of services - all of them served up using Typescript, Hasura, and React Native Web.</StyledConfDesc>,
    thumbImgSrc: shyamalJagannathMyeeTalk,
    videoLink: "https://player.vimeo.com/video/645916393",
    authorDetails: [
      {
        authorImg: shyamalArora,
        name: 'Shyamal Arora',
        designation: 'Platform Engineering Manager,',
        org: "Nutrien",
      },
      {
        authorImg: jagannathVaikuntham,
        name: 'Jagannath Vaikuntham',
        designation: 'Quality Engineer,',
        org: "Nutrien",
      },
      {
        authorImg: myeeRiri,
        name: 'Myee Riri',
        designation: 'Software Development Manager,',
        org: "Nutrien",
      },
    ],
    otherRegardings: [
      {
        talkType: "KEYNOTE",
        title: "Build a GraphQL powered data mesh for your polyglot data",
        linkUrl: "/enterprisegraphql/2021/build-a-graphql-powered-data-mesh-for-your-polyglot-data/",
        recordThumb: tanmaiTalk,
      },
      {
        talkType: "FIRESIDE CHAT",
        title: "Fireside Chat: GraphQL & the Data Mesh",
        linkUrl: "/enterprisegraphql/2021/graphql-the-data-mesh/",
        recordThumb: zhamakTanmaiTalk,
      },
      {
        talkType: "TALK",
        title: "The Ghosts of Databases Past, Present and Future",
        linkUrl: "/enterprisegraphql/2021/the-ghosts-of-databases-past-present-and-future",
        recordThumb: ratchelTalk,
      },
      {
        talkType: "TALK",
        title: "GraphQL, the gateway to a New Service Paradigm",
        linkUrl: "/enterprisegraphql/2021/graphql-the-gateway-to-a-new-service-paradigm/",
        recordThumb: arunTalk,
      },
    ],
  },
  "/enterprisegraphql/2021/graphql-the-gateway-to-a-new-service-paradigm/": {
    metaTags: {
      title: "GraphQL, the gateway to a New Service Paradigm | Enterprise GraphQL Conf",
      canonicalUrl: "https://hasura.io/enterprisegraphql/2021/graphql-the-gateway-to-a-new-service-paradigm/",
      description:
        "As the world's largest provider of crop inputs and services, Nutrien plays a critical role in helping farmers grow food in a sustainable manner. Nutrien has been operating in Australia for more than 150 years providing expertise in a range of different areas, including precision farming services, marketing livestock and wool, agricultural services, finance, insurance, merchandise, and real estate.",
      metaImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/enterprisegraphql/graphql-the-gateway-to-a-new-service-paradigm.png",
    },
    talkType: "TALK",
    title: "GraphQL, the gateway to a New Service Paradigm",
    // isGated: true,
    isGated: false,
    talkDesc: <StyledConfDesc>Almost a year since we introduced Viaduct, Airbnb’s data-oriented service mesh, it now serves the vast majority of Airbnb traffic, and is home to much of our new product development. In the process, we have changed when and how we create and operate services around a familiar organizing principle: a shared GraphQL schema. I’ll go over some of the learnings from taking Viaduct to Airbnb scale, and look ahead to the emerging universe of data-oriented microservice architectures.</StyledConfDesc>,
    thumbImgSrc: arunTalk,
    videoLink: "https://player.vimeo.com/video/645918735",
    authorDetails: [
      {
        authorImg: arunVijayvergiya,
        name: 'Arun Vijayvergiya',
        designation: 'Staff Engineer,',
        org: "Airbnb",
      },
    ],
    otherRegardings: [
      {
        talkType: "KEYNOTE",
        title: "Build a GraphQL powered data mesh for your polyglot data",
        linkUrl: "/enterprisegraphql/2021/build-a-graphql-powered-data-mesh-for-your-polyglot-data/",
        recordThumb: tanmaiTalk,
      },
      {
        talkType: "FIRESIDE CHAT",
        title: "Fireside Chat: GraphQL & the Data Mesh",
        linkUrl: "/enterprisegraphql/2021/graphql-the-data-mesh/",
        recordThumb: zhamakTanmaiTalk,
      },
      {
        talkType: "TALK",
        title: "The Ghosts of Databases Past, Present and Future",
        linkUrl: "/enterprisegraphql/2021/the-ghosts-of-databases-past-present-and-future",
        recordThumb: ratchelTalk,
      },
      {
        talkType: "TALK",
        title: "Improving quality of life for Aussie farmers",
        linkUrl: "/enterprisegraphql/2021/improving-quality-of-life-for-aussie-farmers/",
        recordThumb: shyamalJagannathMyeeTalk,
      },
    ],
  },
};

export { talksDetailsState };
